import React, {useEffect, useState} from 'react';
import { Navbar, Container, Button } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

type ReportItem = {
  integrationId: string;
  name: string;
  lastIteration: string;
};

type ReportDetailItem = {
  time: string;
  documentId: string;
  documentType: string;
  shortId: string;
  status: string;
  validationResultList: any[];
  erpResultList: string[];
};

type SortDirection = 'asc' | 'desc';

const Dashboard: React.FC = () => {
  const [reportList, setReportList] = useState<ReportItem[]>([]);
  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const [reportDetails, setReportDetails] = useState<ReportDetailItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [sortBy, setSortBy] = useState<keyof ReportDetailItem | null>('time');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');

  const navigate = useNavigate();

  const token = sessionStorage.getItem('sessionToken');

  const handleLogout = () => {
    sessionStorage.removeItem('sessionToken');
    navigate('/login');
  };

  // Načtení seznamu reportů
  useEffect(() => {
    const fetchReports = async () => {
      if (!token) return;
      try {
        const res = await fetch('https://fidooflexi.upi-group.cz/report/list', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (!res.ok) {
          console.error("Chyba při získávání report listu");
          return;
        }
        const data = await res.json();
        if (data?.itemList) {
          setReportList(data.itemList);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, [token]);

  const handleSelectReport = async (integrationId: string) => {
    setSelectedReport(integrationId);
    if (!token) return;
    try {
      const res = await fetch('https://fidooflexi.upi-group.cz/report/get', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ integrationId })
      });
      if (!res.ok) {
        console.error("Chyba při získávání detailu reportu");
        return;
      }
      const data = await res.json();
      if (data?.itemList) {
        setReportDetails(data.itemList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleBack = () => {
    setSelectedReport(null);
    setReportDetails([]);
    setSortBy(null);
    setSortDirection('asc');
  };

  const handleSort = (column: keyof ReportDetailItem) => {
    // Omezíme třízení na sloupce, které jsou řetězce nebo datum
    const sortableColumns: (keyof ReportDetailItem)[] = ['time', 'documentId', 'documentType', 'shortId', 'status'];
    if (!sortableColumns.includes(column)) return;

    if (sortBy === column) {
      // Změníme směr, pokud je stejný sloupec
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Nastavíme nový sloupec a defaultní směr 'asc'
      setSortBy(column);
      setSortDirection('asc');
    }
  };

  function getComparableValue(item: ReportDetailItem, key: keyof ReportDetailItem): string | number {
    const value = item[key];
    // Pokud je to pole, převedeme ho na string (např. spojením)
    if (Array.isArray(value)) {
      return value.join(',').toLowerCase();
    }
     // Pokud je to čas, převedeme na timestamp
    if (key === 'time') {
      return new Date(value).getTime();
    }
    return value?.toString().toLowerCase() ?? '';
  }

  const sortedDetails = React.useMemo(() => {
    if (!sortBy) return reportDetails;
    return [...reportDetails].sort((a, b) => {
      const valA = getComparableValue(a, sortBy);
      const valB = getComparableValue(b, sortBy);

      if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
      if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }, [reportDetails, sortBy, sortDirection]);

  const renderSortIndicator = (column: keyof ReportDetailItem) => {
    if (sortBy !== column) return null;
    return sortDirection === 'asc' ? ' ↑' : ' ↓';
  };

  const getStatusBadgeClass = (status: string) => {
    switch (status) {
      case 'success':
        return 'badge bg-success';
      case 'skipped':
        return 'badge bg-warning text-dark';
      case 'error':
        return 'badge bg-danger';
      default:
        return 'badge bg-secondary';
    }
  };

  return (
      <div>
        <Navbar bg="light" expand="lg" className="mb-4">
          <Container className="my-custom-container">
            <Navbar.Brand>FIDOO Reporty</Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <Button variant="outline-danger" onClick={handleLogout}>Odhlásit se</Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <Container className="my-custom-container">

          {loading && <p>Načítám seznam reportů...</p>}

          {!loading && !selectedReport && (
              <>
                <h2>Seznam reportů</h2>
                {reportList.length === 0 && <p>Žádné reporty k dispozici</p>}
                {reportList.length > 0 && (
                    <table className="table table-striped table-hover mt-3">
                      <thead className="table-dark">
                      <tr>
                        <th>Integration ID</th>
                        <th>Název</th>
                        <th>Poslední iterace</th>
                        <th>Akce</th>
                      </tr>
                      </thead>
                      <tbody>
                      {reportList.map((item) => (
                          <tr key={item.integrationId}>
                            <td>{item.integrationId}</td>
                            <td>{item.name}</td>
                            <td>{item.lastIteration}</td>
                            <td>
                              <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => handleSelectReport(item.integrationId)}
                              >
                                Zobrazit detail
                              </button>
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                )}
              </>
          )}

          {selectedReport && (
              <>
                <h2 className="mt-4">Detail reportu (IntegrationId: {selectedReport})</h2>
                <button onClick={handleBack} className="btn btn-info mb-3">
                  Zpět na seznam reportů
                </button>
                {reportDetails.length === 0 && <p>Žádná data k dispozici.</p>}
                {reportDetails.length > 0 && (
                    <table className="table table-striped table-hover">
                      <thead className="table-dark">
                      <tr>
                        <th onClick={() => handleSort('time')} style={{cursor: 'pointer'}}>
                          Čas {renderSortIndicator('time')}
                        </th>
                        <th onClick={() => handleSort('documentId')} style={{cursor: 'pointer'}}>
                          Dokument ID {renderSortIndicator('documentId')}
                        </th>
                        <th onClick={() => handleSort('documentType')} style={{cursor: 'pointer'}}>
                          Typ dokumentu {renderSortIndicator('documentType')}
                        </th>
                        <th onClick={() => handleSort('shortId')} style={{cursor: 'pointer'}}>
                          Zkratka {renderSortIndicator('shortId')}
                        </th>
                        <th onClick={() => handleSort('status')} style={{cursor: 'pointer'}}>
                          Status {renderSortIndicator('status')}
                        </th>
                        <th>Výsledky přenosu</th>
                        <th>Výsledky validace</th>
                      </tr>
                      </thead>
                      <tbody>
                      {sortedDetails.map((detail, index) => (
                          <tr key={index}>
                            <td>{detail.time}</td>
                            <td>{detail.documentId}</td>
                            <td>{detail.documentType}</td>
                            <td>{detail.shortId}</td>
                            <td>
                            <span className={getStatusBadgeClass(detail.status)}>
                              {detail.status}
                            </span>
                            </td>
                            <td>
                              {[...new Set(detail.erpResultList)].map((result, idx) => (
                                  <React.Fragment key={idx}>
                                    {result}
                                    <br/>
                                  </React.Fragment>
                              ))}
                            </td>
                            <td>
                              {[...new Set(detail.validationResultList)].map((result, idx) => (
                                  <React.Fragment key={idx}>
                                    {result}
                                    <br/>
                                  </React.Fragment>
                              ))}
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
                )}
              </>
          )}

          </Container>
      </div>
);
};

export default Dashboard;
